<template>
    <section class="cpt-archive">

        <div v-if="displayTaxonomyFilters" class="filters">
            <select v-for="(terms, taxonomy) in taxonomyHeirarchicalFilters"
                    :id="taxonomy"
                    :name="taxonomy"
                    class="filter"
                    :key="taxonomy"
                    @change.prevent="filterBy($event, taxonomy)">
                <option value="" :selected="!hasTermFilters" disabled>Filter by {{ taxonomyKeyNames[taxonomy] }}
                </option>
                <template v-for="term in terms" :key="term.slug">
                    <option :value="term.slug">{{ term.name }}</option>

                    <template v-if="term.hasOwnProperty('children')">
                        <option v-for="term in term.children"
                                :value="term.slug"
                                :key="term.slug"
                        >&nbsp; &nbsp;{{ term.name }}
                        </option>
                    </template>
                </template>
            </select>
            <button class="filters__clear" @click.prevent="clearFilters">Clear</button>
        </div>

        <slot name="cards" v-bind="{ cards: filteredPosts }">
            <div v-if="filteredPosts.length > 0" class="flex-cols flex-cols-1 sm:flex-cols-2 lg:flex-cols-3">
                <pa-card v-for="(card, index) in filteredPosts" :key="index" :card="card"></pa-card>
            </div>
            <div v-else class="no-results">
                <p>No items found. <a v-if="hasTermFilters" href="javascript:void(0)" @click.prevent="clearFilters">Clear filters</a></p>
            </div>
        </slot>

    </section>
</template>

<script>
export default {
    props: {
        posts: {
            type: Array,
        },
        taxonomies: {
            type: Array,
        },
        taxonomyKeyNames: {
            type: Object,
        },
        queryParams: {
            type: Object
        },
        cardClasses: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            termFilter: {},
        };
    },
    mounted() {
        if (this.queryParams !== {} && this.queryParams !== null && this.queryParams !== undefined) {
            Object.keys(this.queryParams).forEach(taxonomy => {
                let termIndex = this.taxonomyFilters[taxonomy].findIndex((term) => term.slug === this.queryParams[taxonomy]);
                this.termFilter[taxonomy] = this.taxonomyFilters[taxonomy][termIndex];
            });
        }
    },

    computed: {
        displayTaxonomyFilters() {
            return this.taxonomies.reduce((count, taxonomy) => {
                return count + taxonomy.length;
            }, 0) > 0;
        },

        hasTermFilters() {
            return Object.keys(this.termFilter).length > 0;
        },

        filteredPosts() {
            let posts = this.posts.map((post) => {
                post.classes = `${post.classes} ${this.cardClasses}`;
                return post;
            });

            if (!this.hasTermFilters) {
                return this.alphabetizeByTitle(posts);
            }

            let activeTaxonomyKeyNames = Object.keys(this.termFilter);

            return this.alphabetizeByTitle(
                posts.filter((post) => {
                    for (let activeTaxonomyKeyName of activeTaxonomyKeyNames) {
                        if (!this.postHasTerm(post, this.termFilter[activeTaxonomyKeyName].term_id)) {
                            return false;
                        }
                    }

                    return true;
                })
            );
        },

        taxonomyFilters() {
            let filters = {};

            for (let taxonomy of this.taxonomies) {
                for (let term of taxonomy) {
                    if (!filters.hasOwnProperty(term.taxonomy)) {
                        filters[term.taxonomy] = [];
                    }
                    filters[term.taxonomy] = [...filters[term.taxonomy], term];
                }
            }
            return filters;
        },

        taxonomyHeirarchicalFilters() {
            let filters = {};

            for (let taxonomy of this.taxonomies) {
                let children = {};
                for (let term of taxonomy) {
                    if (!filters.hasOwnProperty(term.taxonomy)) {
                        filters[term.taxonomy] = [];
                    }

                    // term is a child taxonomy
                    if (term.parent > 0) {
                        children[term.parent] = children.hasOwnProperty(term.parent) ? [...children[term.parent], term] : [term];
                        continue;
                    }

                    filters[term.taxonomy] = [...filters[term.taxonomy], term];
                }

                // add children
                for (let term of taxonomy) {
                    if (!children.hasOwnProperty(term.term_id)) {
                        continue;
                    }

                    let parentTermIndex = filters[term.taxonomy].findIndex((tax_term) => {
                        return tax_term.term_id === term.term_id;
                    });

                    if (parentTermIndex >= 0) {
                        filters[term.taxonomy][parentTermIndex].children = children[term.term_id];
                    }
                }
            }
            return filters;
        },
        taxonomyKeyNameOptions() {
            let options = {};
            for (let [key, name] of Object.entries(this.taxonomyKeyNames)) {
                options[key] = name.replace(' Taxonomy', '');
            }
            return options;
        }
    },

    methods: {
        filterBy(event, taxonomy) {
            let termIndex = this.taxonomyFilters[taxonomy].findIndex((term) => term.slug === event.target.value);
            this.termFilter[taxonomy] = JSON.parse(JSON.stringify(this.taxonomyFilters[taxonomy][termIndex]));
        },

        clearFilters() {
            this.termFilter = {};
        },

        hasTermFilterFor(taxonomy_key) {
            return Object.keys(this.termFilter).includes(taxonomy_key);
        },

        postHasTerm(post, termId) {
            if (post.hasOwnProperty('terms') && Array.isArray(post.terms)) {
                return post.terms.map(term => term.term_id).includes(termId);
            }
            return false;
        },

        alphabetizeByTitle(posts, descending = false) {
            return posts.sort((a, b) => {
                if (descending) {
                    return a.post_title < b.post_title ? 1 : -1;
                }
                return a.post_title > b.post_title ? 1 : -1;
            });
        },
    }
}
</script>