import ExampleComponent from "@/components/ExampleComponent";
import CPTArchive from "./CPTArchive";
import Card from "./cards/Card";

/**
 * Register global Vue components below and they will be auto-registered
 * with the root instance.
 */
export default {
    'example-component': ExampleComponent,
    'cpt-archive': CPTArchive,
    'pa-card': Card
};
