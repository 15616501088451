<template>
    <div class="example-component">
        <h3>
            <slot name="heading">Example Component Default Heading</slot>
        </h3>

        <slot>This is an example component's default slot content.</slot>
    </div>
</template>

<script>
    export default {
        mounted() {
            console.log("Example component mounted!");
        }
    }
</script>

<style lang="scss">
    .example-component {
        margin: 20px 0;
        padding: 20px 0;
        border-top: 3px solid #333;
        border-bottom: 3px solid #333;
    }
</style>
