import { createApp } from "vue";
import config from './../config.json';
import components from '@/components/global';
import usePluginData from "@/composables/use-plugin-data";

let plugin_prefix = config.plugin_prefix;

let vue_prefix = plugin_prefix.replaceAll('_', '-');

let plugin_data_obj = plugin_prefix + 'data';

let vue_wrapping_class = window[plugin_data_obj].vue_wrapping_class;

let elements = document.querySelectorAll(`.${vue_prefix}${vue_wrapping_class}`);

for (let element of elements) {
    let pluginApp = createApp({
        mounted() {
            console.log("PA Base Plugin JS enqueued & Vue instance mounted!");
        },

        setup() {
            usePluginData(plugin_data_obj);
        }
    });

    for (const [name, component] of Object.entries(components)) {
        pluginApp.component(name, component);
    }

    pluginApp.mount(element);
}