<template>
    <div class="card" :class="cardClasses" :style="getStyle()" @mouseenter="toggleButtonStyles(true)"
         @mouseleave="toggleButtonStyles(false)">
        <a class="wrapper" :class="wrapperClasses" :href="href">
            <slot name="image">
                <div v-if="displayImage()" class="block image">
                    <img :src="card.imagePath" :alt="card.imageAlt">
                </div>
            </slot>
            <div v-if="displayContent()" class="block content">
                <slot name="header">
                    <div v-if="displayCardHeader()" class="card__header">
                        <card-title :titleLine1="card.titleLine1" :titleLine2="card.titleLine2"
                                    :titleTag="card.titleTag"></card-title>
                    </div>
                </slot>
                <slot name="body">
                    <div v-if="displayCardBody()" class="card__body" v-text="card.body"></div>
                    <p v-if="card.price" class="price">${{ card.price }}</p>
                </slot>
                <slot name="footer">
                    <div v-if="displayCardFooter()" class="card__footer">
                        <button ref="button" class="button card__cta" :class="ctaClasses"
                                v-text="card.ctaText"></button>
                    </div>
                </slot>
            </div>
        </a>

        <div v-if="displayProductCardFooter()" class="card__footer--product">
            <a v-if="!isSimpleProduct" ref="button" class="button card__cta" :class="ctaClasses" :href="href"
               v-text="card.ctaText"></a>
            <button v-if="isSimpleProduct" ref="AddToCartButton" class="button card__cta add-to-cart"
                    :class="ctaClasses" @click="addToCart()">
                Add to cart
            </button>
            <span ref="addToCartMessage" class="add-to-cart-message">{{ addToCartMessage }}</span>
            <span ref="addToCartErrorMessage" class="add-to-cart-error-message">{{ addToCartErrorMessage }}</span>
            <span ref="loading" class="loading"></span>
        </div>
    </div>
</template>

<script>
import CardTitle from "./CardTitle";

export default {
    components: {CardTitle},
    props: {
        card: {
            props: {
                type: {
                    type: String,
                },
                slug: {
                    type: String,
                },
                source: {
                    type: String,
                },
                classes: {
                    type: String,
                },
                hoverEffects: {
                    type: Array
                },
                url: {
                    type: String
                },
                imagePath: {
                    type: String
                },
                imageAlt: {
                    type: String,
                },
                imageClass: {
                    type: String,
                },
                bgColor: {
                    type: String,
                },
                titleTag: {
                    type: String
                },
                titleLine1: {
                    type: String,
                },
                titleLine2: {
                    type: String
                },
                body: {
                    type: String
                },
                price: {
                    type: String
                },
                displayCta: {
                    type: Boolean
                },
                ctaTheme: {
                    type: String
                },
                ctaClass: {
                    type: String
                },
                ctaText: {
                    type: String
                },
                productData: {
                    type: Array
                },
            },
            default: false
        },
        showImage: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            addToCartMessage: 'Added to cart :)',
            addToCartErrorMessage: 'Add to cart failed :(',
        };
    },
    computed: {
        type: function () {
            return this.card.type ? this.card.type : 'y-card';
        },
        href: function () {
            return this.card.url ? this.card.url : 'javascript:void(0)'
        },
        hoverEffectClasses: function () {
            if (this.card.hoverEffects) {
                return this.card.hoverEffects.join(' ');
            }
            return '';
        },
        cardClassList: function () {
            let classList = [];
            classList.push(this.card.type);
            classList.push(this.card.slug);
            classList.push(this.card.classes);

            if (this.card.url) {
                classList.push(this.hoverEffectClasses);
            }

            return classList;
        },
        wrapperClassList: function () {
            let classList = [];

            classList.push(this.card.imageClass);
            if (this.card.url) {
                classList.push('a');
            }

            return classList;
        },
        ctaClassList: function () {
            let classList = [];
            classList.push(this.card.ctaTheme);
            classList.push(this.card.ctaClass);

            return classList;
        },
        cardClasses: function () {
            return this.cardClassList.join(' ');
        },
        wrapperClasses: function () {
            return this.wrapperClassList.join(' ');
        },
        ctaClasses: function () {
            return this.ctaClassList.join(' ');
        },
        isSimpleProduct: function () {
            return this.card.productData.type === 'simple';
        }
    },
    methods: {
        getStyle: function () {
            let style = '';
            if (this.card.imagePath && this.type === 'z-card')
                style += 'background-image: url(' + this.card.imagePath + ');';
            if (this.card.bgColor)
                style += 'background-color:' + this.card.bgColor + ';';
            return style;
        },
        displayImage: function () {
            return this.showImage && (this.card.imagePath && (this.type === 'y-card' || this.type === 'x-card'));
        },
        displayContent: function () {
            return this.card.titleLine1 || this.card.titleLine2 || this.card.body || (this.card.ctaText && this.card.href);
        },
        displayCardHeader: function () {
            return this.card.titleLine1 || this.card.titleLine2;
        },
        displayCardBody: function () {
            return this.card.body;
        },
        displayCardFooter: function () {
            return (this.card.source !== 'product') && this.card.displayCta && this.card.ctaText && this.card.url;
        },
        displayProductCardFooter: function () {
            return (this.card.source === 'product') && this.card.displayCta && this.card.ctaText && this.card.url;
        },
        toggleButtonStyles: function (add) {
            if (this.$refs.button) {
                if (add) {
                    this.$refs.button.classList.add('hover');
                } else {
                    this.$refs.button.classList.remove('hover');
                }
            }
        },
        addToCart: function () {
            this.$refs.AddToCartButton.classList.add('inactive');
            this.$refs.loading.classList.add('active');
            let quantity = 1;

            fetch("/?add-to-cart=" + this.card.productData.id + "&quantity=" + quantity, {method: 'get'})
                .then(() => {
                    this.$refs.loading.classList.remove('active');
                    if (this.$refs.addToCartMessage) {
                        this.$refs.addToCartMessage.classList.add('active');
                    }
                    console.log(quantity + ' item(s) added to cart');

                    // Update cart-item-count in header
                    let cartItemCountEl = document.getElementById('cart-item-count');
                    let cartItemCount = cartItemCountEl.getAttribute('data-cart-item-count')
                    cartItemCountEl.innerHTML = parseFloat(cartItemCount) + parseFloat(quantity);
                    console.log('cart-item-count updated');
                })
                .catch(err => {
                    this.$refs.loading.classList.remove('active');
                    if (this.$refs.addToCartErrorMessage) {
                        this.$refs.addToCartErrorMessage.classList.add('active');
                    }
                    console.log(err);
                });

        }
    }
}
</script>
